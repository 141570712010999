<template>
  <div>
    <Tag v-for="(item, idx) in selectedItems" :key="idx" :name="idx" closable @on-close="removeItem(item.id)">
      {{ item.title }}
    </Tag>
    <Form inline :label-width="80">
      <FormItem label="搜索商品">
        <Input v-model="queryForm.keyword" type="text" placeholder="搜索" />
      </FormItem>
      <FormItem label="选择店铺">
      <Button @click="showUserModel">{{ userInfo.name ? userInfo.name : "选择用户" }}</Button>
      <Button icon="md-trash" style="margin-left:8px" v-if="userInfo.name" @click="clearUserInfo"></Button>
    </FormItem>
    </Form>
    <Table stripe
           :columns="columns"
           :data="itemList"
           :loading="tableLoading"
           @on-select="onSelect"
           @on-select-cancel="onSelectCancel"
           @on-select-all="onSelectAll"
           @on-select-all-cancel="onSelectAllCancel"></Table>
    <Pager :total="itemListCount" :current.sync="page" />
    <Modal v-model="showUserSelectorModel" width="80%" title="选择用户">
      <UserList @selectionChanged="userSelectionChanged" v-if="showUserSelectorModel" />
    </Modal>
  </div>
</template>

<script>
import Pager from "@/components/Pager.vue"

import { queryItem, queryItemByIds, queryItemIds } from "@/api"
import { toHumanDate, centiToOne, imgHolder } from "@/util"
import UserList from "@/components/user/UserList"

export default {
  name: "ItemSelector",
  components: { Pager, UserList },
  props: {
    selected: { type: Array, default: () => [] },
    max: { type: Number, default: 500 },
    itemAlias: { type: String, default: "" },
  },
  data() {
    return {
      newSelected: [],
      ids: {},
      itemList: [],
      itemListCount: 0,
      keyword: null,
      tableLoading: false,
      selectedItems: [],
      createdAtRange: ["", ""],
      queryForm: { status: "" },
      columns: [
        { type: "selection", fixed: "left", align: "center", width: 50 },
        { title: "ID", key: "id", width: 80, maxWidth: 80, fixed: "left" },
        { title: "标识", key: "itemAlias", width: 100, maxWidth: 200, fixed: "left" },
        {
          title: "主图",
          width: 100,
          render: (h, params) => {
            return imgHolder(h, params.row.image?.url)
          },
          fixed: "left",
        },
        {
          title: "标题",
          maxWidth: 200,
          minWidth: 100,
          key: "title",
          fixed: "left",
        },
        {
          title: "卖家",
          render(h, p) {
            return h("div", p.row.seller.nickname)
          },
          width: 100,
          fixed: "left",
        },
        {
          title: "价格",
          render(h, params) {
            return h("div", centiToOne(params.row.price))
          },
        },
        {
          title: "创建时间",
          key: "createdAt",
          render: (h, p) => h("div", toHumanDate(p.row.createdAt)),
        },
        {
          title: "上架时间",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.listedAt, defText: "-" },
            }),
        },
      ],
      userInfo: {},
      page: 1,
      showUserSelectorModel: false
    }
  },
  watch: {
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
    page() {
      this.reload()
    },
    async newSelected(newV) {
      if (newV && newV.length > 0) {
        this.selectedItems = await queryItemByIds(newV)
        const idMap = await queryItemIds(this.selectedItems.map(i => i.itemAlias))
        this.selectedItems.forEach(item => {
          item.id = idMap[item.itemAlias]
        })
      } else {
        this.selectedItems = []
      }
      this.$emit("itemsChanged", this.selectedItems)
    },
  },
  async mounted() {
    if (this.selected) {
      this.newSelected = this.newSelected.concat(this.selected)
    } else {
      this.newSelected = []
    }
    await this.reload()
  },
  methods: {
    onSelect(selection, row) {
      if (this.newSelected.length >= this.max) {
        this.$Message.error("已经达到允许的最大选择数量, 只有其中第" + this.max + "个会生效")
        return
      }
      if (!this.newSelected.includes(row.id)) {
        this.newSelected.push(row.id)
      }
      this.$emit("selectionChanged", this.newSelected, this.selectedItems)
    },
    onSelectCancel(selection, row) {
      this.newSelected = this.newSelected.filter(i => i != row.id)
      this.$emit("selectionChanged", this.newSelected, this.selectedItems)
    },
    onSelectAll(selection) {
      selection.forEach(s => {
        this.onSelect(selection, s)
      })
    },
    onSelectAllCancel(selection) {
      this.itemList.forEach(item => {
        this.onSelectCancel(selection, item)
      })
    },
    removeItem(id) {
      this.itemList = this.itemList.map(item => {
        if (item.id == id) {
          item._checked = false
        }
        return item
      })
      this.onSelectCancel(null, { id })
    },
    async reload() {
      try {
        this.tableLoading = true
        const q = this.queryForm
        let startTime = null,
          endTime = null
        if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
          startTime = this.createdAtRange[0].getTime()
          endTime = this.createdAtRange[1].getTime()
        }
        const keyword = q.keyword === "" ? null : q.keyword
        const userAlias = this.userInfo.alias
        console.log(this.userInfo)
        const { items, count } = await queryItem(userAlias, this.page, keyword, startTime, endTime, 1, null)
        const idMap = await queryItemIds(items.map(i => i.itemAlias))
        items.forEach(item => {
          item.id = idMap[item.itemAlias]
          if (this.newSelected.includes(item.id)) {
            item._checked = true
          }
        })
        this.itemList = items
        this.itemListCount = count
        this.tableLoading = false
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    showUserModel() {
      this.showUserSelectorModel = true
    },
    async clearUserInfo() {
      this.userInfo = {}
      this.page = 1
      await this.reload()
    },
    async userSelectionChanged(e) {
      this.userInfo = e
      this.showUserSelectorModel = false
      this.page = 1
      await this.reload()
    }
  },
}
</script>
