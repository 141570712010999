<template>
  <ContentWrapper :links="topLinks">
    <Form :labelWidth="120" style="padding-right: 100px" :disabled="false">
      <FormItem label="名称">
        <Input v-model="name" placeholder="请输入名称" />
      </FormItem>
      <FormItem label="描述">
        <Input v-model="desc" placeholder="请输入描述" type="textarea" :rows="5" />
      </FormItem>
      <FormItem label="选择商品">
        <Button @click="showItemSelector = true">选择商品</Button>
      </FormItem>

      <FormItem>
        <draggable class="groupbuy-item-list" element="ul" :list="selectedItems" :options="{ group: 'title', animation: 150 }"
          :no-transition-on-drag="true">
          <span v-for="(item, idx) in selectedItems" :key="idx" :name="idx" class="groupbuy-item">
            <span>
              {{ item.title }}
            </span>

            <span style="margin-left: 12px; width: 80px;">
              原价  {{ centiToOne(item.price) }}
            </span>

            <span style="margin-left: 12px; width: 110px;" v-if="disabled && sourceList.includes(item.itemId)">
              当前成团数  {{ item.currentCount }}
            </span>

            <div class="groupbuy-item-input-wrapper" v-if="disabled && sourceList.includes(item.itemId)">
              <span class="groupbuy-item-input-label2">虚拟成团数</span> <Input v-model="item.virtualCount"
                                                                          style="width: 80px;" />
              <Button @click="updateItemVirtualCount(item)" style="margin-left: 12px;">修改
              </Button>
            </div>

            <div class="groupbuy-item-input-wrapper">
              <span class="groupbuy-item-input-label">集单价</span> <Input
              :disabled="disabled && sourceList.includes(item.itemId)" v-model="item.groupbuyPrice"
              placeholder="请输入集单价" />
            </div>

            <span class="groupbuy-item-input-wrapper">
              <span class="groupbuy-item-input-label">成团数</span> <Input
              :disabled="disabled && sourceList.includes(item.itemId)" v-model="item.totalCount"
              placeholder="请输入成团数" />
            </span>

            <Button @click="changeItemList(item)" v-if="disabled && sourceList.includes(item.itemId)"
                    style="margin-left: 12px;">{{ item.status === 1 ? "下架" : "上架" }}
            </Button>

            <Icon type="md-trash" @click="removeItem(item.id)" v-if="!(disabled && sourceList.includes(item.itemId))" />
          </span>
        </draggable>
      </FormItem>

      <FormItem label="活动开放时间">
        <DatePicker v-model="createDateRange" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" placeholder="选择开始于结束的时间"
                    style="width: 300px" :disabled="disabled"></DatePicker>
      </FormItem>

      <FormItem>
        <Button size="large" type="primary" @click="publish">发布</Button>
      </FormItem>
    </Form>

    <Modal v-model="showItemSelector" fullscreen>
      <ItemSelector v-if="showItemSelector" :selected="targetIds" @selectionChanged="itemSelectionChanged" />
      <div slot="footer">
        <Button @click="showItemSelector=false" size="large" type="primary">确定</Button>
      </div>
    </Modal>

  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import ItemSelector from "@/components/promocode/ItemSelector"
import draggable from "vuedraggable"
import {
  createGroupbuy,
  queryItemIds,
  queryItemByIds,
  getGroupbuyDetil,
  updateGroupbuy,
  updateGroupVirtualCount,
  listGroupbuyItem,
  unlistGroupbuyItem,
} from "@/api"
import { fixPrice, centiToOne } from "@/util"
import "@/components/tableUtil"

export default {
  name: "GroupbuyCreatePage",
  components: { ContentWrapper, ItemSelector, draggable },
  data() {
    return {
      topLinks: [{ title: "集单", link: this.$route.path }],
      createDateRange: ["", ""],
      // 编辑商品前的id list
      targetIds: [],
      page: 1,
      name: "",
      desc: "",
      alias: undefined,
      showItemSelector: false,
      selectedItems: [],
      // 初始的id列表
      sourceList: [],
      disabled: false,
    }
  },
  async mounted() {
    this.alias = this.$route.query.alias
    await this.init()
  },
  methods: {
    fixPrice,
    centiToOne,
    async init() {
      if (this.alias) {
        const detail = await getGroupbuyDetil(this.alias)
        // 判断活动是否开启
        if (detail.startTime < new Date().getTime()) {
          this.disabled = true
        }
        this.name = detail.name
        this.desc = detail.desc
        this.selectedItems = detail.items.map(item => {
          item.price = item.originalPrice
          item.groupbuyPrice = centiToOne(item.groupbuyPrice)
          return item
        })
        this.targetIds = detail.items.map(item => {
          return item.itemId
        })
        this.sourceList = detail.items.map(item => {
          return item.itemId
        })
        this.createDateRange = [detail.startTime, detail.expireTime]
      }
    },
    async itemSelectionChanged(selection) {
      this.targetIds = selection
      const itemList = await queryItemByIds(selection)
      const idMap = await queryItemIds(itemList.map(i => i.itemAlias))
      this.selectedItems = itemList.map(item => {
        const itemId = idMap[item.itemAlias]
        if (this.selectedItems.some(i => i.itemId == itemId)) {
          const selectedItem = this.selectedItems.filter(subItem => subItem.itemId == itemId)
          return selectedItem[0]
        } else {
          let groupbuyItem = {}
          groupbuyItem.itemId = itemId
          groupbuyItem.title = item.title
          groupbuyItem.price = item.price
          groupbuyItem.itemAlias = item.itemAlias
          groupbuyItem.groupbuyPrice = null
          groupbuyItem.totalCount = null
          return groupbuyItem
        }
      })
    },
    removeItem(id) {
      console.log(this.selectedItems)
      this.targetIds = this.targetIds.filter(i => i != id)
      this.selectedItems = this.selectedItems.filter(u => u.id != id)
    },
    selectItems() {
      this.showItemSelector = false
    },
    async publish() {
      try {
        let selectedItems = JSON.parse(JSON.stringify(this.selectedItems))
        selectedItems = selectedItems.map((item, index) => {
          item.groupbuyPrice = this.fixPrice(item.groupbuyPrice)
          item.sortOrder = 500 - index
          return item
        })
        const promo = {
          name: this.name,
          desc: this.desc,
          startTime: this.createDateRange[0],
          expireTime: this.createDateRange[1],
          selectedItems: selectedItems,
        }

        if (this.alias) {
          promo.alias = this.alias
          await updateGroupbuy(promo)
          this.$Message.success("集单修改成功")
          this.$router.back(-1)
        } else {
          await createGroupbuy(promo)
          this.$Message.success("集单创建成功")
          this.$router.push({ name: "GroupbuyQueryPage" })
        }
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async updateItemVirtualCount(item) {
      try {
        await updateGroupVirtualCount({
          groupbuyAlias: this.alias,
          itemAlias: item.itemAlias,
          virtualCount: item.virtualCount,
        })
        this.$Message.success("修改成功")
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async changeItemList(item) {
      try {
        if (item.status === 1) {
          await unlistGroupbuyItem({
            groupbuyAlias: this.alias,
            itemAlias: item.itemAlias,
          })
          this.$Message.success("修改成功")
          item.status = 0
        } else {
          await listGroupbuyItem({
            groupbuyAlias: this.alias,
            itemAlias: item.itemAlias,
          })
          this.$Message.success("修改成功")
          item.status = 1
        }
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
  },
}
</script>
<style>
.groupbuy-item-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.groupbuy-item {
  display: flex;
  align-items: center;
}

.groupbuy-item-input-wrapper {
  display: flex;
  align-items: center;
}

.groupbuy-item-input-label {
  width: 60px;
  margin-left: 12px;
  margin-right: 8px;
}

.groupbuy-item-input-label2 {
  width: 80px;
  margin-left: 12px;
  margin-right: 8px;
}
</style>
